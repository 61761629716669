import React from 'react';
import eureka from 'eureka';

import MessageToast from 'src/common/plugins/toast.plugin';
import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import { MicroFrontendProps } from 'src/types';
import Tiles from '../components/Tiles';

const { Spinner } = eureka.components;

type Props = MicroFrontendProps;

const WelcomePage: React.FC<Props> = (props) => {
  // useEffect(() => {}, []);
  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__app">
        <div className="fd-app">
          <main className="fd-app__main">
            <React.Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              <div className="home-page">
                <div>test</div>
                <Tiles history={props.history} config={props.config} />
              </div>
            </React.Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="welcome-page-dialog" />
      <MessageToast key="welcome-page-toast" />
    </div>
  );
};

export default WelcomePage;
